export default {
	apiKey: "AIzaSyBWMDJuw8guseH3qsviCkvzxfYFdnSPARA",
	authDomain: "montavistamodelun.firebaseapp.com",
	databaseURL: "https://montavistamodelun.firebaseio.com",
	projectId: "montavistamodelun",
	storageBucket: "montavistamodelun.appspot.com",
	messagingSenderId: "796706186483",
	appId: "1:796706186483:web:7f1a5719c9534d6e144386",
	measurementId: "G-ZBEJWZ4C3K",
};
